.uplot, .uplot *, .uplot :before, .uplot :after {
  box-sizing: border-box;
}

.uplot {
  width: min-content;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

.u-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.u-wrap {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
}

.u-over, .u-under {
  position: absolute;
}

.u-under {
  overflow: hidden;
}

.uplot canvas {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.u-axis {
  position: absolute;
}

.u-legend {
  text-align: center;
  margin: auto;
  font-size: 14px;
}

.u-inline {
  display: block;
}

.u-inline * {
  display: inline-block;
}

.u-inline tr {
  margin-right: 16px;
}

.u-legend th {
  font-weight: 600;
}

.u-legend th > * {
  vertical-align: middle;
  display: inline-block;
}

.u-legend .u-marker {
  width: 1em;
  height: 1em;
  margin-right: 4px;
  background-clip: padding-box !important;
}

.u-inline.u-live th:after {
  content: ":";
  vertical-align: middle;
}

.u-inline:not(.u-live) .u-value {
  display: none;
}

.u-series > * {
  padding: 4px;
}

.u-series th {
  cursor: pointer;
}

.u-legend .u-off > * {
  opacity: .3;
}

.u-select {
  pointer-events: none;
  background: #00000012;
  position: absolute;
}

.u-cursor-x, .u-cursor-y {
  pointer-events: none;
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
}

.u-hz .u-cursor-x, .u-vt .u-cursor-y {
  border-right: 1px dashed #607d8b;
  height: 100%;
}

.u-hz .u-cursor-y, .u-vt .u-cursor-x {
  border-bottom: 1px dashed #607d8b;
  width: 100%;
}

.u-cursor-pt {
  pointer-events: none;
  will-change: transform;
  border: 0 solid;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-clip: padding-box !important;
}

.u-axis.u-off, .u-select.u-off, .u-cursor-x.u-off, .u-cursor-y.u-off, .u-cursor-pt.u-off {
  display: none;
}
/*# sourceMappingURL=uPlot.min.css.map */
